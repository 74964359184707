import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Sticky from 'react-stickynode'
import { VelocityComponent } from 'velocity-react'
import Image from '../Image/Image'
import Icon from '../Icon/Icon'
import BookHeader from '../BookHeader/BookHeader'
import BookForm from '../BookForm/BookForm'
import BookContact from '../BookContact/BookContact'
import styles from './StickyBook.module.scss'

/*
const BookElement = props => {
  const data = props.data
  const label = data.label
  const title = data.title
  const imageSizes = data.image.localFile.childImageSharp.fluid
  let content = null
  if (props.type === 'room') {
    const formLabels = data.content.book_form.book_form_labels
    content = (
      <div className={styles.book}>
        <div className={styles.info}>
          <BookHeader label={label} title={title} />
          <BookForm locale={props.locale} labels={formLabels} pinned={props.pinned} whiteButton />
        </div>
        <Image
          fluid={imageSizes}
          imageProps={{
            style: {
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }
          }}
          />
      </div>
    )
  } else if (props.type === 'restaurant' || props.type === 'spa') {
    const list = data.content.book_contact.list
    const addendum = data.content.book_contact.addendumm
    content = (
      <div className={styles.book}>
        <div className={styles.info}>
          <BookHeader label={label} title={title} />
          <BookContact list={list} addendum={addendum} />
        </div>
        <Image
          fluid={imageSizes}
          imageProps={{
            style: {
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }
          }}
        />
      </div>
    )
  }
  return (
    <div>
      {content}
    </div>
  )
}

class StickyBook extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dropdown: {
        active: null,
        visible: false
      },
      pinned: false
    }
    this.handleStickyChange = this.handleStickyChange.bind(this)
  }

  showDropdown (index) {
    this.setState({
      dropdown: {
        active: index,
        visible: true
      },
      ...this.state.pinned
    })
  }

  closeDropdowns () {
    this.setState({
      dropdown: {
        active: null,
        visible: false
      },
      ...this.state.pinned
    })
  }

  handleStickyChange (status) {
    if (status.status === 0) {
      this.setState({
        ...this.state.dropdown,
        pinned: false
      })
    } else if (status.status === 2) {
      this.setState({
        ...this.state.dropdown,
        pinned: true
      })
    }
  }

  render () {
    let wrapModifier = ''
    let containerModifier = ''
    if (this.props.hideUnpinned) {
      wrapModifier = styles.hideUnpinned
      containerModifier = this.state.pinned ? styles.visible : styles.hidden
    }

    let contentBlocks = []
    contentBlocks.push(this.props.bookContent.restaurant)
    contentBlocks.push(this.props.bookContent.room)
    contentBlocks.push(this.props.bookContent.spa)

    return (
      <VelocityComponent
        animation={{
          opacity: 1
        }}
        duration={300}
        easing='easeInOutSine'
        runOnMount
        >
        <div className={`${styles.wrap} ${wrapModifier}`}>
          <Sticky bottomBoundary='#content' onStateChange={this.handleStickyChange}>
            <div className={`${styles.container} ${containerModifier}`}>
              <div className={styles.grid}>
                {contentBlocks.map((block, i) => {
                  const buttonModifier = block.button.alpha ? styles.alpha : ''
                  const dropdownModifier = this.state.pinned ? styles.pinned : styles.unpinned
                  const visible = this.state.dropdown.active === i
                  const translateVisible = this.state.pinned ? '0%' : '-100%'
                  const translateHidden = this.state.pinned ? '-100%' : '0%'
                  const type = block.button.type
                  const data = block.dropdown

                  return (
                    <div className={styles.column} key={i}>
                      <button
                        type='button'
                        className={`${styles.button} ${buttonModifier}`}
                        onClick={() => { this.showDropdown(i) }}
                        >
                        <span>{block.button.label}</span>
                      </button>
                      <VelocityComponent
                        animation={{
                          opacity: visible ? 1 : 0,
                          translateY: visible ? [translateVisible, translateHidden] : [translateHidden, translateVisible]
                        }}
                        display={visible ? 'block' : 'none'}
                        easing='easeInOutSine'
                        duration={300}
                        >
                        <div className={`${styles.dropdown} ${dropdownModifier}`}>
                          <BookElement
                            type={type}
                            locale={this.props.locale}
                            data={data}
                            pinned={this.state.pinned}
                          />
                          <button
                            type='button'
                            className={styles.closeDropdown}
                            onClick={() => { this.closeDropdowns() }}
                          >
                            <Icon name='modalCloseWhite' />
                          </button>
                        </div>
                      </VelocityComponent>
                    </div>
                  )
                })}
              </div>
            </div>
          </Sticky>
          <div
            style={{display: this.state.dropdown.visible ? 'block' : 'none'}}
            className={styles.overlay}
            onClick={() => { this.closeDropdowns() }}
          />
        </div>
      </VelocityComponent>
    )
  }
}
*/

const StickyBook = props => (
  <div />
)

StickyBook.propTypes = {
  locale: PropTypes.string.isRequired,
  bookContent: PropTypes.object.isRequired
}

export default StickyBook

export const blocksFragment = graphql`
  fragment StickyBookContent on wordpress___sticky_book {
    id
    language_code
    restaurant {
      button {
        label
        type
        alpha
      }
      dropdown {
        label
        title
        content {
          book_contact {
            list {
              link
              text
            }
            addendumm
          }
        }
        image {
          alt_text
          source_url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
    room {
      button {
        label
        type
        alpha
      }
      dropdown {
        label
        title
        content {
          book_form {
            book_form_labels {
              arrival
              departure
              adults
              wordpress_children
              book
            }
          }
        }
        image {
          alt_text
          source_url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
    spa {
      button {
        label
        type
        alpha
      }
      dropdown {
        label
        title
        content {
          book_contact {
            addendumm
            list {
              link
              text
            }
          }
        }
        image {
          alt_text
          source_url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`
